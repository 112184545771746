<template>
   <b-modal
        id="confirm-delete"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        size="lg"
        v-model="modalShow"
    >
        <div class="my-2">
            <div v-if="type === 'warning'" class="d-flex flex-column align-items-center justify-center-center">
                <feather-icon
                    id="view-departamento"
                    icon="AlertCircleIcon"
                    :class="'text-'+type+' mb-2'"
                    size="80"
                />
                <h3 class="text-center">Tem certeza que deseja {{ titleAction }} o uso de regiões?</h3>
                <p class="font-14 mb-2">Essa ação irá:</p>
                <p class="mb-0 font-14 width-content-text text-center" v-for="(content, index) in contentText" :key="index">
                    <feather-icon class="mr-1" icon="DiscIcon" color="black" size="4"/> 
                    {{ content }}
                </p>
                <div class="d-flex mt-2">
                    <b-button :disabled="loading" variant="primary-button" class="mr-3" @click="cancelAction">
                        Cancelar
                    </b-button>
                    <b-button :disabled="loading" :variant="'outline-'+type" @click="confirmAction">
                        <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Sim, {{ buttonText }}
                    </b-button>
                </div>
            </div>
            <div v-if="type === 'waiting'" class="d-flex flex-column align-items-center justify-center-center">
                <feather-icon
                    id="view-departamento"
                    icon="AlertCircleIcon"
                    class="text-warning mb-2"
                    size="80"
                />
                <h3 class="text-center">Aguarde</h3>
                <p class="font-14 text-center">Desativando o uso de regiões para o seu Departamento Regional. <br/>
                    Você será avisado assim que a operação for concluída.</p>
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div v-if="type === 'success'" class="d-flex flex-column align-items-center justify-center-center">
                 <feather-icon
                    id="view-departamento"
                    icon="CheckCircleIcon"
                    class="text-success mb-2"
                    size="80"
                />
                <h3 class="text-center">Concluído</h3>

                <p v-if="activated" class="font-14 text-center">O uso de regiões foi concluído com sucesso.</p>
                <p v-else class="font-14 text-center">Este departamento não utiliza mais regiões.</p>

                <b-button variant="primary-button" class="mt-2" @click="cancelAction"> Ok </b-button>
            </div>
            <div v-if="type === 'error'" class="d-flex flex-column align-items-center justify-center-center">
                <feather-icon
                    id="view-departamento"
                    icon="XCircleIcon"
                    class="text-danger mb-2"
                    size="80"
                />
                <h3 class="text-center">Ocorreu um erro.</h3>
                <p class="font-14 text-center">
                    Não se preocupe, os dados estão seguros.<br/>
                    Por favor, tente novamente ou contate nosso suporte.
                </p>
                <b-button variant="primary-button" class="mt-2" @click="cancelAction"> Ok </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';

export default {
    components: {
        BModal, BButton
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        active: {
            required: true,
            type: Boolean|null
        },
        success: {
            required: true,
            type: Boolean|null
        },
        devideByRegion: {
            requried: true,
            type: Boolean|null
        }
    },

     data() {
        return {
            loading: false,
            activated: false,
            type: 'warning',
            titleAction: 'ativar',
            buttonText: 'ativar',
            contentText: [],
            modalTypes: [
                {
                    type: 'warningtrue',
                    titleAction: 'ativar',
                    buttonText: 'ativar',
                    contentText: [
                        'Habilitar o cadastro de regiões;',
                        'Permitir o vínculo de Unidades Operacionais com regiões;',
                        'Todas as suas Unidades Operacionas atuais não serão afetadas e estarão presente em uma região padrão.',
                    ]
                },
                {
                    type: 'warningfalse',
                    titleAction: 'desativar',
                    buttonText: 'inativar',
                    contentText: [
                        'Desabilitar o cadastro de regioes;',
                        'Inativar todas as regiões existentes no seu DR;',
                        'Todas as suas Unidades Operacionas atuais serão desvinculadas das regiões atuais de forma automática.',
                    ]
                }
            ]
        }
    },

    methods: {
        cancelAction(modalEvent) {
            modalEvent.preventDefault();

            const backState = this.type !== 'success';
            this.$emit('canceled', backState);
        },

        confirmAction(modalEvent) {
            modalEvent.preventDefault();

            this.loading = true;
            this.activated = this.$props.devideByRegion;

            if(!this.$props.devideByRegion) {
                this.type = 'waiting';
            }

            this.$emit('confirmed', modalEvent);
        },
    },

    watch: {
        modalShow() {
            this.loading = false;

            this.type = 'warning';

            const type = this.type + this.$props.active;

            this.modalTypes.map(item => {
                if(item.type == type) {
                    this.titleAction = item.titleAction;
                    this.contentText = item.contentText;
                    this.buttonText = item.buttonText;
                }
            });
        },

        success(value) {
            if(value !== null) {
                this.type = value ? 'success' : 'error';
            }
        }
    }
}
</script>

<style>
.font-14 {
    font-size: 1rem;
}

.list-style {
    list-style: none;
}

.width-content-text {
    max-width: 60%;
}
</style>