<template>
    <section class="regiao mb-4">
        <ConfirmModal
            :modalShow="modalConfirm.showModal"
            :typeModal="modalConfirm.typeModal"
            itemIdentifier="região"
            :infoText="{
                success: 'Todas as unidades e pontos de atendimento vinculados a essa região serão ativados também.',
                warning: 'Todas as unidades e pontos de atendimento vinculados a essa região serão inativados também.',
            }"
            @confirmed="changeStateRegiao"
            @canceled="closeModal"
        />

        <ConfirmModalDevideByRegion
            :modalShow="modalChangeDevideByRegion.showModal"
            :active="devideByRegion"
            :success="successChangeDevideByRegion"
            :devideByRegion="devideByRegion"
            @canceled="closeModal"
            @confirmed="changeDevideByRegion"
        />

        <b-row class="mb-1 pl-1">
            <label class="mb-0 font-size-16">Este departamento faz divisões por regiões?*</label>
            <b-form-radio-group
                v-model="devideByRegion"
                :options="divideRegionOptions"
                class="ml-2"
                :state="devideByRegionError ? false:true"
                :disabled="disabledDevideByRegion ||  loadingData || hasCampaignDisable"
                name="radio-validation"
                @change="changeDivideByRegion"
                v-b-tooltip.hover.right
                :title="textDisabledButton"
            />
            <b-form-invalid-feedback :state="devideByRegionError ? false:true">
                {{ devideByRegionError }}
            </b-form-invalid-feedback>
        </b-row>
        <b-row :class="{
                'lista-regioes' : !editing,
                'lista-form-editar' : editing,
            }"
        >
            <b-col v-if="!editing">
                <div v-for="regiao in regioes" :key="regiao.id_regiao">
                    <label class="font-weight-bold font-size-15">Nome da Região</label>
                    <p>{{ regiao.descricao }}</p>
                    <hr class="color-hr">
                </div>
            </b-col>
            <b-col v-if="editing && showForm">
                <b-form
                    :class="{'opacity-50': loadingData}"
                    ref="form"
                    :style="{height: trHeight}"
                    class="repeater-form"
                >
                    <b-row v-for="(item, index) in formItems"
                        :key="item.controlFormId"
                        :id="item.controlFormId"
                        ref="row"
                    >
                        <b-col>
                            <b-form-group
                                label="Nome da Região*"
                                label-for="nameRegiao"
                            >
                            
                                <b-form-input
                                    autocomplete="off"
                                    v-mask="('X'.repeat(250))"
                                    :disabled="loadingData || !item.ativo && !item.new"
                                    v-model="item.nameRegiao"
                                    id="nameRegiao"
                                    :state="item.error ? false:null"
                                    placeholder="Ex: Região Sul"
                                />
                                 <small v-if="item.error" class="text-danger">
                                    {{ item.error }}
                                </small>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-button v-if="item.ativo && !item.new"
                                :disabled="loadingData"
                                variant="warning"
                                class="mt-0 mt-md-2"
                                @click="openModalConfirm(false, item.idRegiao)"
                            >
                                <feather-icon
                                    icon="MinusIcon"
                                    class="mr-25"
                                />
                                <span>Inativar região</span>
                            </b-button>
                            <b-button v-if="!item.ativo && !item.new"
                                :disabled="loadingData"
                                variant="custom-blue"
                                class="mt-0 mt-md-2"
                                @click="openModalConfirm(true, item.idRegiao)"
                            >
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-25"
                                />
                                <span>Ativar região</span>
                            </b-button>
                            <b-button v-if="item.new"
                                :disabled="loadingData"
                                variant="outline-danger"
                                class="mt-0 mt-md-2"
                                @click="removeForm(index)"
                            >
                                <feather-icon
                                    icon="XIcon"
                                    class="mr-25"
                                />
                                <span>Remover</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
                {{ regionDevide }}
                <b-button
                    :disabled="loadingData"
                    variant="custom-blue"
                    class="mt-0 mt-md-2"
                    @click="repeateForm"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Adicionar outra região</span>
                </b-button>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { BRow, BCol, BForm, BFormInput, BFormGroup,
BButton, BFormRadioGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import ConfirmModalDevideByRegion from '@/views/components/custom/modals/ModalConfirmChangeDevideByRegion.vue';

import handleErrorsService from './Services/handleErrors';

import { heightTransition } from '@core/mixins/ui/transition'
import { getInfoUserDepartamento } from "@core/utils/store/getStore";
import { storeAtualizarDivisaoPorRegiao } from "@core/utils/store/setStore";

export default {
    components: {
        BRow, BCol, BForm, BFormInput,
        BFormGroup, BButton,
        BFormRadioGroup, BFormInvalidFeedback,
        ConfirmModal, ConfirmModalDevideByRegion
    },

    props: {
        edit: Boolean,
        save: Boolean,
        hasCampaign: Boolean,
        regionDevide: {},
        idDepartamento: Number
    },

    mixins: [heightTransition],

    data() {
        return {
            devideByRegion: null,
            devideByRegionOld: null,
            devideByRegionError: null,
            successChangeDevideByRegion: null,
            firstSelectionChangeDeviceByRegion: false,
            showForm: false,
            divideRegionOptions: [
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
            ],
            loadingData: true,
            disabledDevideByRegion: true,
            hasCampaignDisable: false,
            textDisabledButton: '',
            editing: false,
            regioes: [],
            formItems: [
                {
                    controlFormId: 0,
                    nameRegiao: null,
                    idRegiao: null,
                    ativo: null,
                    new: true,
                    error: null
                }
            ],
            itemChangeState: null,
            modalConfirm: {
                showModal: false,
                typeModal: 'success',
            },
            modalChangeDevideByRegion: {
                showModal: false
            },
            nextFormId: 1,
        }
    },

    mounted() {
        this.devideByRegion = this.regionDevide != null ? this.regionDevide : getInfoUserDepartamento('divisao_por_regiao');
        this.devideByRegionOld = getInfoUserDepartamento('divisao_por_regiao');

        if(this.$props.edit) {
            this.disabledDevideByRegion = false;
            this.editing = true;
        }

        if(this.devideByRegion === null) {
            this.firstSelectionChangeDeviceByRegion = true;
        }

        if(this.devideByRegion) {
            this.loadDataRegioes();
            this.showForm = true;
            this.initTrHeight();
        } else {
            this.loadingData = false;
        }
    },

    created() {
        window.addEventListener('resize', this.initTrHeight);
    },

    destroyed() {
        window.removeEventListener('resize', this.initTrHeight);
    },

    methods: {
        loadDataRegioes() {
            this.$http.get(this.$api.regiao(), {
                params: {
                   ativo: !this.editing ? true : '',
                   id_departamento: this.idDepartamento
                }
            }).then(({ data }) => {
                this.loadingData = false;
                this.formItems = [];

                data.map((regiao, index) => {
                    this.formItems.push({
                        controlFormId: index,
                        nameRegiao: regiao.descricao,
                        idRegiao: regiao.id_regiao,
                        ativo: regiao.ativo,
                        new: false
                    })
                });

                this.showForm = true;

                this.nextFormId = data.length !== 0 ? data.length : 1;

                this.initTrHeight();
                this.regioes = data;
            })
        },

        saveRegioes() {
            if(getInfoUserDepartamento('divisao_por_regiao') !== null && !getInfoUserDepartamento('divisao_por_regiao')) {
                this.$emit('saved');
                return;
            }

            const parameters = {
                regioes: this.preparaDataToSave(this.formItems)
            };

            let saveRegiao = this.needToSave();

            if(saveRegiao || !this.devideByRegion) {
                this.$http.put(this.$api.regiao(), parameters).then(() => {
                    storeAtualizarDivisaoPorRegiao(this.devideByRegion)
                    this.$emit('saved');

                }).catch(({ response: { data } }) => {
                    handleErrorsService.handleErrosRegioes(data, this.formItems);
                    this.handleAnimationFormErrors(data);

                    this.$emit('hasError');
                });
                return;
            }

            this.$emit('saved');
        },

        handleAnimationFormErrors(dataErrors) {
            this.$nextTick(() => {
                let errors = dataErrors.errors;
                let error = dataErrors.error;

                let height = Object.keys(
                    errors ? errors : error
                ).length * 17;
                this.trAddHeight(height);
            });
        },

        openModalConfirm(value, itemId) {
            this.modalConfirm.typeModal = 'warning';

            if(value) {
                this.modalConfirm.typeModal = 'success';
            }

            this.itemChangeState = itemId;
            this.modalConfirm.showModal = true;
        },

        changeStateRegiao() {
            this.$http.patch(this.$api.regiaoParameter(this.itemChangeState)).then(() => {
                this.modalConfirm.showModal = false;

                let itemChanged = this.formItems.find(regiao => regiao.idRegiao == this.itemChangeState);
                itemChanged.ativo = !itemChanged.ativo;
                itemChanged.error = null;

            }).catch(({ response: { data } }) => {
                let itemChanged = this.formItems.find(regiao => regiao.idRegiao == this.itemChangeState);
                itemChanged.error = data.error ?? data.errors;

                this.$nextTick(() => {
                    this.trAddHeight(20);
                });

                this.modalConfirm.showModal = false;
            });
        },

        changeDevideByRegion() {
            this.$http.patch(this.$api.changeDevideByRegion()).then(() => {
                storeAtualizarDivisaoPorRegiao(this.devideByRegion);

                this.successChangeDevideByRegion = true;

                if(this.devideByRegion) {
                    this.loadDataRegioes();
                    return;
                }

                this.showForm = false;
            }).catch(() => {
                if(this.devideByRegion) {
                    this.modalChangeDevideByRegion.showModal = false;
                    this.showForm = false;
                }

                this.successChangeDevideByRegion = false;
            })
        },

        closeModal(backState) {
            this.modalConfirm.showModal = false;
            this.modalChangeDevideByRegion.showModal = false;

            if(backState) {
                this.devideByRegion = this.devideByRegionOld;
            }
        },

        needToSave() {
            let saveRegiao = false;

            if(this.regioes.length == 0 || this.regioes.length !== this.formItems.length) {
                saveRegiao = true;
            }

            this.regioes.map((regiao, index) => {
                if(regiao.descricao !== this.formItems[index].nameRegiao) {
                    saveRegiao = true;
                }
            });

            return saveRegiao;
        },

        repeateForm() {
            this.formItems.push({
                controlFormId: this.nextFormId,
                new: true
            });

            this.nextFormId += 1;

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight);
            })
        },

        removeForm(index) {
            if(this.formItems.length > 1) {
                this.formItems.splice(index, 1);
                this.trTrimHeight(this.$refs.row[0].offsetHeight);
            }
        },

        initTrHeight() {
             if(this.devideByRegion && this.editing) {
                this.trSetHeight(null)
                this.$nextTick(() => {
                    this.trSetHeight(this.$refs.form.scrollHeight);
                })
             }
        },

        changeDivideByRegion() {
            this.successChangeDevideByRegion = null;

            if(this.devideByRegionOld !== null) {
                this.modalChangeDevideByRegion.showModal = true;
            } else if(this.firstSelectionChangeDeviceByRegion && this.devideByRegion) {
                this.showForm = true;
                this.initTrHeight();
            } else {
                this.showForm = false;
            }
        },

        preparaDataToSave(dadosRegioes) {
            let namesRegioes = [];

            if(this.devideByRegion) {
                dadosRegioes.map(regiao => {
                    namesRegioes.push(
                        {
                            'descricao': regiao.nameRegiao,
                            'id_regiao': regiao.idRegiao ?? null
                        }
                    );
                });
            }

            return namesRegioes;
        }
    },

    watch: {
        save() {
            this.saveRegioes();
        },
        hasCampaign(newValue) {
            this.hasCampaignDisable = newValue;
            if(newValue){
                this.textDisabledButton = 'Este departamento já possui campanhas, neste caso não é possível modificar o uso de regiões.'
            }else{
                this.textDisabledButton = '';
            }
        }
    }
}
</script>

<style >
    .repeater-form {
        overflow: hidden;
        transition: .35s height;
    }

    .color-hr {
        border-color: #D8D6DE;
    }

    .lista-regioes {
        max-width: 548px;
    }

    .lista-form-editar {
        max-width: 1130px;
    }
</style>
