export default {
    handleErrosRegioes(errorData, formItens) {
        let error = errorData.errors;

        if(error) {
            formItens.map((regiao, index) => {
                regiao.error = error['regioes.' + index + '.descricao'] ? error['regioes.' + index + '.descricao'][0] : null;
                if(!regiao.error) {
                    regiao.error = error['regioes.' + index + '.id_regiao'] ? error['regioes.' + index + '.id_regiao'][0] : null;
                }
            });
            return;
        }
        
        error = errorData.error;
        formItens.map((regiao, index) => {
            regiao.error = error['regioes.' + index + '.descricao'] ? error['regioes.' + index + '.descricao'] : null;
            if(!regiao.error) {
                regiao.error = error['regioes.' + index + '.id_regiao'] ? error['regioes.' + index + '.id_regiao'] : null;
            }
        });
    }
}