<template>
    <div class="content-wrapper">
        <PageHeader :screenName="pageTitle" :linkItems="linkItems"/>

        <ConfirmModal
            itemIdentifier="usuário"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirectToList"
            @canceled="openCloseModalConfirm"
        />

        <SucessoModal
            :modalShow="modalSuccess.showModal"
            :typeModal="modalSuccess.typeModal"
            :caseModal="modalSuccess.caseModal"
            @confirmed="redirectToList"
        />

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirectToList"
        />

        <TimedAlert variant="danger" :message="erroMessage" :show="errorAlert"/>

         <main class="card p-2">
            <section class="form mb-4">
                <validation-observer ref="departamentoRules">
                    <b-form :class="{'opacity-50': loadingData}">
                        <b-row class="mb-2">
                            <b-col lg="6" md="6" sm="12" class="pr-0">
                                <b-form-group label="Nome do Departamento Regional*" label-for="nomeDepartamento-input">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Descricao"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="loadingData || !canEdit"
                                            :state="errors.length > 0 || form.errors.errorName ? false:null"
                                            id="nomeDepartamento-input"
                                            v-mask="('X'.repeat(250))"
                                            v-model="form.nameDepartamento"
                                        />
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.errorName" class="text-danger">
                                            {{ form.errors.errorName }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="6" sm="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Estado"
                                    rules="required"
                                >
                                    <b-form-group label="Estado" label-for="uf"
                                        :state="errors.length > 0 || form.errors.errorUf ? false:null"
                                    >
                                        <v-select
                                            :disabled="loadingData || !canEdit"
                                            id="uf"
                                            :reduce="(option => option.uf)"
                                            v-model="form.ufSelected"
                                            :options="estatesSelect"
                                            label="nome"
                                            placeholder="Selecione um Estado"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.errorUf" class="text-danger">
                                            {{ form.errors.errorUf }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col lg="4" md="6" sm="12" class="pr-0">
                                <b-form-group label="Sigla do Departamento Regional*" label-for="acronym-input">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="acronym"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="loadingData || !canEdit"
                                            :state="errors.length > 0 || form.errors.acronym ? false:null"
                                            id="acronym-input"
                                            autocomplete="off"
                                            @input="($helpers.CheckAcronymLetters(form.errors, form))"
                                            v-mask="('X'.repeat(15))"
                                            v-model="form.acronym"
                                        />
                                        <small v-if="errors[0]" class="text-danger d-block">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errors.acronym" class="text-danger">
                                            {{ form.errors.acronym }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <ResponsibleField
                            labelName="Responsável por este Departamento Regional"
                            :error="form.errors.erroResponsible"
                            :preSelected="form.responsibleUser"
                            @selected="fillResponsible"
                            :selectDisabled="!canEdit"
                            :idDepartamento="idDepartamento"
                        />
                    </b-form>
                </validation-observer>
            </section>
            <hr>
            <RegiaoForm
                v-if="showRegions"
                :edit="true"
                :save="saveRegiao"
                :hasCampaign="hasCampaign"
                @hasError="handleErrorRegiao"
                @saved="saveRegionData"
            />
            <section class="buttons">
                <b-button id="save-departamento"
                    class="mr-2"
                    variant="custom-blue"
                    @click.prevent="saveForms"
                    :disabled="saving || loadingData"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar alterações</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-departamento"
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="openCloseModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue';
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import ResponsibleField from '@/views/components/custom/field-select-responsible-departamento/ResponsibleField.vue';
import handleErrorsService from './Services/HandleErrors';
import RegiaoForm from './Regiao/RegiaoForm.vue';
import {getUserData} from "@/auth/utils";

import { required } from '@validations';
import { getIdDepartamento, getAcronymDepartamento } from '@core/utils/store/getStore';
import { storeAtualizarDepartamento, setIdDepartamento, setAcronymDepartamento } from "@core/utils/store/setStore";
import {tipoCampanhaEnum} from "@/enum/tipoCampanha";

export default {
    title: 'Editar departamento',

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
        vSelect, ValidationProvider, ValidationObserver,
        TimedAlert, PageHeader, ConfirmModal, SucessoModal, ErrorModal, ResponsibleField,
        RegiaoForm
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Departamentos regionais',
                    routeName: 'departamento-list'
                },
                {
                    name: 'Editar',
                    active: true
                }
            ],
            pageTitle: 'Editar Departamento',
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            required,
            errorAlert: false,
            erroMessage: '',
            saving: false,
            saveRegiao: false,
            savedRegiao: false,
            loadingData: true,
            currentRoute: this.$router.currentRoute.name,
            hasCampaign: false,
            userData: getUserData(),
            form: {
                nameDepartamento: null,
                ufSelected: null,
                acronym: null,
                responsibleUser: null,
                email: null,
                errors: {
                    errorName: null,
                    errorUf: null,
                    acronym: null,
                    erroResponsible: null,
                }
            },
            estatesSelect: [],
            departamentoUsers: [],
            canEdit: false,
            errorRegion: false,
            showRegions: this.$can(actions.ATUALIZAR, subjects.REGIAO),
            idDepartamento: null,
        }
    },

    mounted() {
        if((getIdDepartamento() === null || typeof getIdDepartamento() == 'undefined') && typeof this.$route.params.idDepartamento == "undefined") {
            return this.openModalError();
        }else if(typeof this.$route.params.idDepartamento != "undefined" && this.$route.params.idDepartamento !== getIdDepartamento()) {
            setIdDepartamento(this.$route.params.idDepartamento); 
            setAcronymDepartamento(this.$route.params.acronymDepartamento); 
        }
        this.idDepartamento = getIdDepartamento();
        this.configureHeader();
        this.loadStateData();
        this.loadDepartamentoData();
        this.verifyCampanhaDepartamento();
        this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
    
    },

    methods: {
        configureHeader() {
            this.pageTitle = 'Editar ' + getAcronymDepartamento();
            if(this.$route.meta.linkItems) {
                this.linkItems = this.$route.meta.linkItems;
                this.linkItems[0].name = 'Editar: ' + getAcronymDepartamento();
                return;
            }

            this.linkItems[1].name = 'Editar: ' + getAcronymDepartamento();
        },
        saveRegionData(){
            if(this.showRegions && !this.errorRegion){
                this.openModalSucesso();
                this.saving = false;
            }
        },
        validationFormDepartamento() {

            this.$refs.departamentoRules.validate().then(success => {
                if (success) {
                    this.editDepartamento();
                }
            })
        },

        saveForms() {
            this.saving = true;
            this.errorRegion = false;

            if(this.showRegions){
                this.saveRegiao = !this.saveRegiao;
                return;
            }

            this.validationFormDepartamento();
        },

        handleErrorRegiao() {
            this.errorRegion = true;
            this.saving = false;
        },

        editDepartamento() {
            const parameters = {
                'descricao': this.form.nameDepartamento,
                'uf': this.form.ufSelected,
                'sigla': this.form.acronym,
                'id_usuario_responsavel': this.form.responsibleUser
            };

            this.cleanErrors();

            this.$http.put(this.$api.departamentoParameter(getIdDepartamento()), parameters).then(() => {
                storeAtualizarDepartamento(this.form);
                this.saving = false;

                this.openModalSucesso();
            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosDepartamento(data, this.form.errors);

                if(typeof data.errors == 'string') {
                    this.erroMessage = data.errors;
                    this.errorAlert = !this.errorAlert;
                }

                this.saving = false;
            })
        },

        cleanErrors() {
            this.form.errors.errorName = null;
            this.form.errors.errorUf = null;
            this.form.errors.erroResponsible = null;
        },

        openCloseModalConfirm() {
            this.modal.showModal = !this.modal.showModal;
        },

        openModalSucesso() {
            this.modalSuccess.showModal = true;
        },

        openModalError() {
            this.modalError.showModal = true;
        },

        redirectToList() {
            if(this.showRegions) {
                this.$router.push({ name: 'departamento-view-regiao' });
                return;
            }

            this.$router.push({ name: 'departamento-list' });
        },

        loadDepartamentoData() {
            this.$http.get(this.$api.departamentoParameter(getIdDepartamento())).then(({ data }) => {
                this.form.nameDepartamento = data.descricao;
                this.form.ufSelected = data.uf;
                this.form.acronym = data.sigla;
                this.form.responsibleUser = data.responsavel_id;
                this.form.email = data.responsavel_email;

                this.loadingData = false;
            }).catch(({ response: { data } }) => {
                this.erroMessage = data.errors;
                this.errorAlert = !this.errorAlert;
            });
        },
        verifyCampanhaDepartamento(){
            const params = {
              tipoCampanha: tipoCampanhaEnum.REGIONAL
            }

            this.$http.get(this.$api.campanha(), { params }).then(({ data }) => {
                if (data.length > 0){
                    this.hasCampaign = true;
                }
            });
        },
        loadStateData() {
            this.$http.get(this.$api.getEstados()).then(({ data }) => {
                this.estatesSelect = data;
            });
        },

        fillResponsible(event) {
            this.form.responsibleUser = event;
        },
        verifyPermissions(action) {
            const permissionDN = this.$can(actions[action], subjects.DEPARTAMENTO)

            return [permissionDN].includes(true);
        }
    }
}
</script>


